import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { selectPack, resetSuggestions } from '../actions/PackActions'

const mapStateToProps = state => ({
  no_packages: state.config.no_packages,
})

const mapDispatchToProps = dispatch => ({
    selectPackAction: (id, is_multi, important) => dispatch(selectPack(id, is_multi, important)),
    resetSuggestionsAction: () => dispatch(resetSuggestions()),
})

class ProductSuggestionsComponent extends React.Component {
  codeClick = (e) => {
    const {parent} = this.props
    parent.setState({
      kod: e.target.textContent
    })
  }

  getCodes = (codes) => {
    return codes.map((code, index) => (
      <span className={code.length>23?'long':''} onClick={this.codeClick} key={index}>
        {code}
      </span>
    ))
  }

  getCodeElements = () => {
    const { codes } = this.props
    if((codes.bundles && codes.bundles.length>0) || (codes.products && codes.products.length>0)){
      return (
        <div className="product_suggestions_columns">
          { codes.bundles && (codes.bundles.length > 0) &&
            <div className="sug_col sug_col1">
              <h5>{intl.get('Zestawy')}</h5>
              <div className="sug_col_tags_wrapper">
                <div className="sug_col_tags">
                  {this.getCodes(codes.bundles)}
                </div>
              </div>
            </div>
          }
          <div className="sug_col sug_col2">
            { codes.bundles && (codes.bundles.length > 0) &&
              <h5>{intl.get('Produkty')}</h5>
            }
            <div className="sug_col_tags">
              {this.getCodes(codes.products)}
            </div>
          </div>
        </div>
      )
    } else if (codes && codes.products.length > 0) {
      return this.getCodes(codes.products)
    } else if ((codes && codes.products.length === 0) && (codes.products && codes.products.length === 0)) {
      return (
        <div className="loading">{intl.get('Nie znaleziono żadnych kodów')}</div>
      )
    } else {
      return (
        <div className="loading">{intl.get('ładowanie...')}</div>
      )
    }
  }

  componentWillUnmount() {
    const { resetSuggestionsAction } = this.props
    resetSuggestionsAction()
  }

  render() {
    const { visible, no_packages } = this.props
    return (
      <div className={'ramka center product_suggestions'+(['off'].includes(visible)?' d-none':'')}>
        { no_packages &&
          <h4>{intl.get('Produkty')}</h4>
        }
        { !no_packages &&
          <h4>{intl.get('Produkty w paczce')}</h4>
        }
        { this.getCodeElements() }
      </div>
    )
  }
}

const ProductSuggestions = connect(mapStateToProps, mapDispatchToProps)(ProductSuggestionsComponent)
export default ProductSuggestions
