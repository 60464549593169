import { withRouter } from 'react-router-dom'
import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { logUserOut } from '../actions/AuthActions'
import { ReactComponent as LogoutIcon } from '../assets/logout.svg'

const mapStateToProps = state => ({
  authenticated: state.auth.session.token !== false,
  name: state.auth.session.username,
  packing_zone_name: state.auth.session.packing_zone_name
})

const mapDispatchToProps = dispatch => ({
    logUserOutAction: () => dispatch(logUserOut())
})

class HeaderComponent extends React.Component {
  logout = () => {
    const {logUserOutAction} = this.props
    Popup.create({
      content: intl.get('Czy na pewno chcesz się wylogować?'),
      buttons: {
        right: [{
          text: intl.get('Tak'),
          key: 'enter',
          action: function () {
            Popup.close()
            logUserOutAction()
          }
        },
        {
          text: intl.get('Nie'),
          className: 'cancel-button',
          action: function () {
            Popup.close()
          }
        }]
      }
    })
  }

  render() {
    const {authenticated, name, packing_zone_name} = this.props

    return(
      <header>
        <div className="logo"/>
        { authenticated ? (
          <div className="nav">
            <div className="auth_info">
              <span className="auth_info_name">
                { name }
              </span>
              <span className="auth_info_pakowalnia">
                { packing_zone_name }
              </span>
            </div>
            <button type="button" className="logout" onClick={this.logout}>
              <LogoutIcon/>
              Wyloguj
            </button>
          </div>
        ):''}
      </header>
    )
  }
}
const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)

export default withRouter(Header)
