import React from 'react';

class ProgressBar extends React.Component {
  render() {
    const { percent } = this.props
    return(
      <div className="progressbar-gutter">
        <div className="progressbar-bar" style={{width: `${percent}%`}}/>
      </div>
    )
  }
}

export default ProgressBar
