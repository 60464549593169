import { SELECT_SHIPPING, GET_SHIPPING, SAVE_SHIPPING, DELETE_SHIPPING, CLEAR_SHIPPING, SET_SEARCHING, LOGOUT_USER, API_ERROR} from '../constants/ActionTypes'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import Odoo from '../utils/Odoo'

function api_error(dispatch, e){
  if (e && e.status === 403){
    Popup.alert(intl.get('Sesja wygasła'))
    dispatch({
      type: LOGOUT_USER
    })
  } else {
    dispatch({
      type: API_ERROR,
      message: e.message
    })
  }
}

export function selectShipping(shipping) {
  return dispatch => {
    dispatch({
      type: SELECT_SHIPPING,
      payload: shipping
    })
    if(shipping === false){
      dispatch({
        type: GET_SHIPPING,
        payload: false
      })
    }
  }
}

export function deleteShipping(shipping_id) {
  return dispatch => {
    Odoo.call('packing_zone/delete_shipping', {shipping_id}).then(res => {
      Popup.close()
      dispatch({
        type: DELETE_SHIPPING,
        payload: shipping_id
      })
    }).catch(error => {
      Popup.clearQueue()
      Popup.close()
      api_error(dispatch, error)
    })
  }
}

export function getShipping(shipping_id, order_id) {
  let params = {
    shipping_id,
    order_id
  }
  return dispatch => {
    dispatch({
      type: SET_SEARCHING,
      payload: true
    })
    Odoo.call('packing_zone/get_shipping_data', params).then(res => {
      if(!res.id){
        res.shipping_parcels_ids = res.shipping_parcels_ids.map(p => p[2])
      }
      dispatch({
        type: GET_SHIPPING,
        payload: res
      })
    }).catch(error => {
      api_error(dispatch, error)
    }).then(() => {
      dispatch({
        type: SET_SEARCHING,
        payload: false
      })
    })
  }
}

export function clearShippingData(){
  return dispatch => {
    dispatch({
      type: CLEAR_SHIPPING
    })
  }
}

export function saveShipping(id, data, order_id) {
  let params = data
  if(id){
    params.id = id
  }
  if(order_id){
    params.sale_order_id = order_id
  }

  return dispatch => {
    Odoo.call('packing_zone/save_shipping_data', params).then(res => {
      dispatch({
        type: SAVE_SHIPPING,
        payload: res
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}
