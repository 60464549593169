import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import Popup from 'react-popup'
import { getOrder, confirmDone, printInvoice, printLabelAgain, printErrorLabel, getNextOrder, selectPack, returnToHome } from '../actions/PackActions'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  print_invoice_automatically: state.config.print_invoice_automatically,
  dont_show_invoice_print_confirm: state.config.dont_show_invoice_print_confirm,
  use_packing_zone_error_label: state.config.use_packing_zone_error_label,
  auto_open_next_order: state.config.auto_open_next_order,
  order_id: state.router.location.query.order ? parseInt(state.router.location.query.order) : false,
  order: state.pack.order,
  use_multi_search: state.config.use_multi_search,
  show_scan_input_instead_cart_view: state.config.show_scan_input_instead_cart_view
})

const mapDispatchToProps = dispatch => ({
  getOrderAction: (code, id) => dispatch(getOrder(code, id)),
  selectPackAction: () => dispatch(selectPack()),
  confirmDoneAction: () => dispatch(confirmDone()),
  printLabelAgainAction: (order_id) => dispatch(printLabelAgain(order_id)),
  printErrorLabelAction: (order_id) => dispatch(printErrorLabel(order_id)),
  printInvoiceAction: (order_id) => dispatch(printInvoice(order_id)),
  getNextOrderAction: (order_id) => dispatch(getNextOrder(order_id)),
  returnToHomeAction: () => dispatch(returnToHome())
})

class DoneComponent extends React.Component {
  componentDidMount() {
    const { getOrderAction, authenticated, order_id, order } = this.props
    if(authenticated && !order) getOrderAction(false, order_id)
  }

  confirmDone = (order_id) => {
    const {
      order,
      print_invoice_automatically,
      dont_show_invoice_print_confirm,
      confirmDoneAction,
      auto_open_next_order,
      returnToHomeAction,
      show_scan_input_instead_cart_view,
      getNextOrderAction,
    } = this.props
    if (order.invoice_option && !print_invoice_automatically && !dont_show_invoice_print_confirm) {
      Popup.create({
        content: intl.get('Do tego zamówienia wymagana jest faktura, na pewno chcesz kontynuować?'),
        buttons: {
          right: [{
            text: intl.get('Tak'),
            key: 'enter',
            action: function () {
              Popup.close()
              if (show_scan_input_instead_cart_view) {
                returnToHomeAction()
              } else if(auto_open_next_order) {
                getNextOrderAction(order_id);
              } else {
                confirmDoneAction()
              }
            }
          },
          {
            text: intl.get('Anuluj'),
            className: 'cancel-button',
            action: function () {
              Popup.close()
            }
          }]
        }
      })
    } else {
      if (show_scan_input_instead_cart_view) {
        returnToHomeAction()
      } else if(auto_open_next_order) {
        getNextOrderAction(order_id);
      } else {
        confirmDoneAction()
      }
    }
  }

  printLabelAgain = () => {
    const { order_id, printLabelAgainAction } = this.props
    Popup.create({
      content: intl.get('Na pewno chcesz drukować etykietę jeszcze raz?'),
      buttons: {
        right: [{
          text: intl.get('Tak'),
          key: 'enter',
          action: function () {
            Popup.close()
            printLabelAgainAction(order_id)
          }
        },
        {
          text: intl.get('Anuluj'),
          className: 'cancel-button',
          action: function () {
            Popup.close()
          }
        }]
      }
    })
  }

  printErrorLabel = () => {
    const { order_id, printErrorLabelAction } = this.props
    Popup.create({
      content: intl.get('Na pewno chcesz drukować etykietę zastępczą?'),
      buttons: {
        right: [{
          text: intl.get('Tak'),
          key: 'enter',
          action: function () {
            Popup.close()
            printErrorLabelAction(order_id)
          }
        },
        {
          text: intl.get('Anuluj'),
          className: 'cancel-button',
          action: function () {
            Popup.close()
          }
        }]
      }
    })
  }

  printInvoice = () => {
    const { order_id, printInvoiceAction } = this.props
    Popup.create({
      content: intl.get('Na pewno chcesz wydrukować fakturę?'),
      buttons: {
        right: [{
          text: 'Tak',
          key: 'enter',
          action: function () {
            Popup.close()
            printInvoiceAction(order_id)
          }
        },
        {
          text: intl.get('Anuluj'),
          className: 'cancel-button',
          action: function () {
            Popup.close()
          }
        }]
      }
    })
  }

  render() {
    const { authenticated, order, use_packing_zone_error_label, order_id } = this.props
    if(!authenticated) return (<Redirect to='/login' />)
    let odoo = window.ODOO.url + (window.ODOO.port?':'+window.ODOO.port:'')
    return (
      <div className="container">
        <div className="ramka done">
          <h2>
            {intl.get('Zapisano!')}
            <div className="subheader">{intl.get('Wszystkie dane zostały poprawnie zapisane w systemie.')}</div>
          </h2>
          { order &&
            <>
              <div className="buttons_wrap">
                <div>
                  <button onClick={this.printInvoice} className={`print_invoice ${order.invoice_option?'highlighted':'secondary'}`}>{intl.get('Drukuj dokument') + ' - ' + intl.get(order.invoice_option_doc)}</button>
                  <button className="secondary print_label" onClick={this.printLabelAgain}>{intl.get('Ponownie drukuj etykietę')}</button>
                  {use_packing_zone_error_label &&
                    <button className="secondary print_label" onClick={this.printErrorLabel}>{intl.get('Drukuj etykietę zastępczą')}</button>
                  }
                </div>
                <button onClick={() => this.confirmDone(order_id)}>{intl.get('Kontynuuj')}</button>
              </div>
              <div className="summary">
                <h4>{intl.get('Zamówienie')} {order.name}</h4>
                <div className="notes f_group">
                  <label>{intl.get('Uwagi klienta')}:</label>
                  <div style={{'fontStyle': 'italic'}}>{order.note}</div>
                </div>
                <div className="stock_location f_group">
                  <label>{intl.get('Sprzedawca')}:</label>
                  <div>{order.shop_id}</div>
                </div>
                <div className="date_order f_group">
                  <label>{intl.get('Data zamówienia')}:</label>
                  <div>{order.date_order}</div>
                </div>
                <div className="amount_total f_group">
                  <label>{intl.get('Kwota zamówienia')}:</label>
                  <div>{order.amount_total}</div>
                </div>
                <div className="carrier f_group">
                  <label>{intl.get('Metoda dostawy')}:</label>
                  <div>
                    <div>{order.carrier_name}</div>
                    <div className="shippinglogo">
                      <img src={`${odoo}/ideaerp_shipment_${order.carrier_comp}/static/img/logo.png`} alt="carrier"/>
                    </div>
                  </div>
                </div>
                <div className="delivery f_group">
                  <label>{intl.get('Adres dostawy')}:</label>
                  <div>
                    <div>{order.delivery_name}</div>
                    <div>{order.delivery_street}</div>
                    <div>{order.delivery_zip} {order.delivery_city}</div>
                    {order.delivery_phone &&
                    <div>{order.delivery_phone}</div>
                    }
                    {order.delivery_email &&
                    <div>{order.delivery_email}</div>
                    }
                  </div>
                </div>
                {order.allegro_account &&
                  <div className="allegro_account f_group">
                    <label>{intl.get('Login Allegro sprzedawcy')}:</label>
                    <div>{order.allegro_account}</div>
                  </div>
                }
                {order.allegro_client_login &&
                  <div className="allegro_client f_group">
                    <label>{intl.get('Login Allegro klienta')}:</label>
                    <div>{order.allegro_client_login}</div>
                  </div>
                }
                <div className="invoice f_group">
                  <label>{intl.get('Potwierdzenie')}:</label>
                  <div>{order.invoice_option ? <strong style={{'color': '#f00'}}>{intl.get(order.invoice_option_doc)}</strong> : intl.get('other')}</div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

const Done = connect(mapStateToProps, mapDispatchToProps)(DoneComponent)
export default withRouter(Done)
