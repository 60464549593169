import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import pack from './pack'
import shipping from './shipping'
import config from './config'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  pack,
  shipping,
  config
})
