import { LOGIN_USER, LOGOUT_USER, LOGIN_USER_ERROR, SET_LANG, SET_HIGH_CONTRAST} from '../constants/ActionTypes'

const resetState = {
  invalid: false,
  lang: false,
  high_contrast: false,
  session: {
    token: false,
    username: false,
    packing_zone_name: false
  }
}

function setSession(session){
  localStorage.setItem('ideaerp_token', session.token)
  localStorage.setItem('ideaerp_username', session.username)
  localStorage.setItem('ideaerp_packing_zone_name', session.packing_zone_name)
}

function setLang(lang){
  localStorage.setItem('lang', lang)
}

function setHighContrast(high_contrast){
  localStorage.setItem('high_contrast', high_contrast)
}

function destroySession(){
  localStorage.removeItem('ideaerp_token')
  localStorage.removeItem('ideaerp_username')
  localStorage.removeItem('ideaerp_packing_zone_name')
}

function getSession(){
  return {
    session: {
      'token': localStorage.getItem('ideaerp_token') || false,
      'username': localStorage.getItem('ideaerp_username') || false,
      'packing_zone_name': localStorage.getItem('ideaerp_packing_zone_name') || false
    },
    high_contrast: localStorage.getItem('high_contrast') || false
  }
}

function getInitialState(){
  return {...getSession(),
    invalid: false
  }
}

export default function auth(state = getInitialState(), action) {
  switch (action.type) {
    case LOGIN_USER:
      setSession(action.payload)
      return { ...state,
        invalid: false,
        ...getSession()
      }
    case LOGOUT_USER:
      destroySession()
      return resetState
    case LOGIN_USER_ERROR:
      return { ...state,
        invalid: true
      }
    case SET_LANG:
      setLang(action.payload)
      return state
    case SET_HIGH_CONTRAST:
      setHighContrast(action.payload)
      return state
    default:
      return state
  }
}
