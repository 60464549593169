import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { history } from '../store'
import Spinner from '../components/Spinner'
import App from '../components/App'
import LoginForm from '../components/LoginForm'
import Packs from '../components/Packs'
import ScanProduct from '../components/ScanProduct'
import Order from '../components/Order'
import Boxes from '../components/Boxes'
import ScanCart from '../components/ScanCart'
import Done from '../components/Done'

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route {...rest} render={(props) => (
      authenticated === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )

const mapStateToProps = state => ({
  authenticated: state.auth.session.token !== false,
  no_packages: state.config.no_packages,
  wozek: state.config.wozek,
  pack: state.router.location.query.pack ? parseInt(state.router.location.query.pack) : false,
  order: state.router.location.query.order ? parseInt(state.router.location.query.order) : false,
  multi: !!state.router.location.query.multi,
  done: !!state.router.location.query.done
})

class RoutesComponent extends React.Component {
  loadComponent = () => {
    const {
      authenticated,
      no_packages,
      wozek,
      pack,
      order,
      multi,
      done
    } = this.props
    if(!authenticated) return [LoginForm, 'LoginForm']

    if(pack || no_packages){
      if(done) return [Done, 'Done']
      if(order){
        return [Order, 'Order']
      } else {
        if(multi){
          return [Boxes, 'Boxes']
        } else {
          return [ScanProduct, 'ScanProduct']
        }
      }
    } else {
      if(wozek){
        return [ScanCart, 'ScanCart']
      } else {
        return [Packs, 'Packs']
      }
    }
  }
  render() {
    const {authenticated} = this.props
    const view = this.loadComponent()
    const viewName = view[1]
    return (
      <ConnectedRouter history={history}>
        <App view={viewName}>
          <Spinner/>
          <Route path="/login" component={LoginForm}/>
          <PrivateRoute exact path="/" authenticated={authenticated} component={view[0]}/>
        </App>
      </ConnectedRouter>
    )
  }
}
const Routes = connect(mapStateToProps)(RoutesComponent)

export default Routes
