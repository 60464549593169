import { withRouter, Redirect } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getPacks, setFilters } from '../actions/PackActions'
import PackListItem from './PackListItem'

function getPackListItem(pack, wozek) {
  return (
    <PackListItem
      key={pack.id}
      pack={pack}
      wozek={wozek}
    />
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  wozek: state.config.wozek,
  packs: state.pack.packs,
  filters: state.pack.filters
})

const mapDispatchToProps = dispatch => ({
    getPacksAction: () => dispatch(getPacks()),
    setFiltersAction: (filters) => dispatch(setFilters(filters)),
})

class PacksComponent extends React.Component {

  componentDidMount() {
    const { getPacksAction, authenticated } = this.props
    if(authenticated) getPacksAction()
  }

  selectPack = (event) => {
    const {selectPackAction} = this.props
    const id = parseInt(event.currentTarget.getAttribute('data-id'))
    const is_multi = parseInt(event.currentTarget.getAttribute('data-multi'))
    const important = parseInt(event.currentTarget.getAttribute('data-important'))
    selectPackAction(id, is_multi, important)
  }

  filterClick = (e) => {
    const { filters, setFiltersAction } = this.props
    let new_filters = {
      ...filters,
      [e.currentTarget.name]: e.currentTarget.checked
    }
    setFiltersAction(new_filters)
  }

  render() {
    const {authenticated, packs, wozek, filters} = this.props

    if(!authenticated) return (<Redirect to='/login' />)

    let packListItems;

    if (packs) {
      packListItems = packs.map(pack => getPackListItem(pack, wozek));
    }
    if (packs) {
      return (
        <div>
          <div className="filters">
            <label htmlFor="filter_pak" className={filters.pak?'checked':''}>
              <input type="checkbox"
                     id="filter_pak"
                     name="pak"
                     onChange={this.filterClick}
                     checked={filters.pak}/>
              Pak
            </label>
            <label htmlFor="filter_multi" className={filters.multi?'checked':''}>
              <input type="checkbox"
                     id="filter_multi"
                     name="multi"
                     onChange={this.filterClick}
                     checked={filters.multi}/>
              Multi
            </label>
          </div>
          <h1>
            <div className="breadcrumb">
              <span>{intl.get('Dostępne zamówienia na pakowalni')}:</span>
            </div>
          </h1>
          <div className={`elem-list ${filters.pak?'show_pak':''} ${filters.multi?'show_multi':''}`}>
            {packListItems}
          </div>
        </div>
      )
    } else {
      return (<div></div>)
    }
  }
}
const Packs = connect(mapStateToProps, mapDispatchToProps)(PacksComponent)
export default withRouter(Packs)
