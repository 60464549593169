import React from 'react'
import { connect } from 'react-redux'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { finishOrder, finishOrderSkipSummary } from '../actions/PackActions'

const mapStateToProps = state => ({
  material_products: state.config.material_products,
  packing_zone_dont_show_summary: state.config.packing_zone_dont_show_summary
})

const mapDispatchToProps = dispatch => ({
  finishOrderAction: (data) => dispatch(finishOrder(data)),
  finishOrderSkipSummaryAction: (data) => dispatch(finishOrderSkipSummary(data))
})

class MaterialsListComponent extends React.Component {
  constructor(props) {
    super(props)
    const { material_products } = props
    this.state = {
      val: material_products.map(m => ({
        id: m[0],
        name: m[1],
        count: 0
      }))
    }
  }


  handleChange = (event, $input) => {
    const { val } = this.state
    if(typeof $input === 'undefined')
      $input = event.currentTarget
    const id = parseInt($input.dataset.id)
    const new_val = val.map(m => ({
      ...m,
      count: (m.id === id) ? (isNaN(parseInt($input.value))? 0 : parseInt($input.value)) : m.count
    }))
    this.setState({
      val: new_val
    })
  }

  increment = (event) => {
    const button = event.currentTarget
    let val = 1
    if (button.classList.contains('counter__minus'))
      val = -1
    let $input = button.parentElement.querySelector('input')
    let new_val = parseInt($input.value) + val
    if (new_val < 0)
      new_val = 0
    $input.value = new_val
    this.handleChange(event, $input)
  }

  getMaterialTile = (material) => {
    return (
      <div className="materials_list__tile"
           data-id={material.id}
           data-val={material.count}
           key={material.id}>
        <label onClick={this.increment}>
          <span>{material.name}</span>
        </label>
        <div className="counter">
          <button className="counter__minus" onClick={this.increment}>-</button>
          <input value={material.count}
                 onChange={this.handleChange}
                 data-id={material.id}
                 className="counter__input"
                 type="integer"
                 min="0"/>
          <button className="counter__plus" onClick={this.increment}>+</button>
        </div>
      </div>
    )
  }

  confirm = () => {
    const { finishOrderAction, data, packing_zone_dont_show_summary, finishOrderSkipSummaryAction } = this.props
    const { val } = this.state
    data.material_product_ids = val.filter(m => {
      return m.count > 0
    })
    if(data.material_product_ids.length === 0){
      Popup.create({
        content: intl.get('Żadne pozycje nie zostały wybrane. Kontynuować?'),
        buttons: {
          right: [{
            text: intl.get('Tak'),
            key: 'enter',
            action: function () {
              if (packing_zone_dont_show_summary) {
                finishOrderSkipSummaryAction(data)
              } else {
                finishOrderAction(data)
              }
              Popup.clearQueue()
              Popup.close()
            }
          }],
          left: [{
            text: intl.get('Nie'),
            className: 'cancel-button',
            action: function () {
              Popup.close()
            }
          }]
        }
      }, true)
    } else {
      if (packing_zone_dont_show_summary) {
        finishOrderSkipSummaryAction(data)
      } else {
        finishOrderAction(data)
      }
      Popup.close()
    }
  }

  clear = () => {
    const { val } = this.state
    const new_val = val.map(m => ({
      ...m,
      count: 0
    }))
    this.setState({
      val: new_val
    })
  }

  render() {
    const { val } = this.state
    return (
      <div id="root">
        <main>
          <div className='materials_header'>
            <button className="pull-left" onClick={this.clear}>{intl.get('Wyczyść')}</button>
            <button onClick={this.confirm} className="pull-right" id="zatwierdz" >{intl.get('Zapisz')}</button>
          </div>
          <div className='materials_list'>
            {val.map(material => this.getMaterialTile(material))}
          </div>
        </main>
      </div>
    )
  }
}

const MaterialsList = connect(mapStateToProps, mapDispatchToProps)(MaterialsListComponent)

Popup.registerPlugin('materials', function (data) {
  this.create({
      title: intl.get('Produkty eksploatacyjne'),
      className: 'big_popup',
      content: <MaterialsList data={data}/>
  })
})
