import { createAction } from 'redux-actions'
import { LOGIN_USER, LOGOUT_USER, SET_HIGH_CONTRAST, GET_ZONES, LOGIN_USER_ERROR, GET_CONFIG, API_ERROR} from '../constants/ActionTypes'
import { selectLang } from './PackActions'
import Odoo from '../utils/Odoo'

export const logUserOut = createAction(LOGOUT_USER)

export function setHighContrast(high_contrast) {
  return dispatch => {
    dispatch({
      type: SET_HIGH_CONTRAST,
      payload: high_contrast
    })
  }
}

export function getZones(){
  return dispatch => {
    let params = {}
    Odoo.login('packing_zone/get_zones', params, false).then(zones => {
      dispatch({
        type: GET_ZONES,
        payload: zones
      })
    }).catch(error => {
      dispatch({
        type: API_ERROR,
        message: error.message
      })
    })
  }
}

export function logUserIn(login, password, packing_zone, packing_zone_password) {
  return dispatch => {
    let params = {login, password, packing_zone, packing_zone_password}
    Odoo.login('packing_zone/login', params).then(session => {
      Odoo.call('packing_zone/get_config', {}, session.token).then(config => {
        dispatch({
          type: GET_CONFIG,
          payload: { ...config }
        })
        dispatch({
          type: LOGIN_USER,
          payload: { ...session }
        })
        dispatch(selectLang((session.lang==='pl_PL') ? 'pl-PL' : 'en-US'))
      }).catch(error => {
        dispatch({
          type: API_ERROR,
          message: error.message
        })
      })
    }).catch(error => {
      dispatch({
        type: LOGIN_USER_ERROR,
        message: error.message
      })
    })
  }
}
